import React from "react";
import { Transaction } from 'journal-parser';

export interface ILedgerContext {
  source: string[];
  updateSource: (newSource: string[]) => void;
  data: Transaction[] | {error: string};
}

export const LedgerContext = React.createContext<ILedgerContext>({
  source: [],
  updateSource: (_: string[]): void => { },
  data: []
});

// TODO: Switch to useReducer