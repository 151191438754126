import React from "react";
import { parse, Transaction, validateTransaction, balancesByAccountFromTransactions } from "journal-parser";

import { ILedgerContext, LedgerContext } from "../LedgerStore";

const COOKIE_JOURNAL_SOURCE = "journal-source";

function parseAndCatchErrors(codeLines: string[]): Transaction[] | { error: string } {
  try {
    return parse(codeLines);
  } catch (error) {
    return { error };
  }
}

const ls = {
  set: (key: string, value: string) => {
    // @ts-ignore
    globalThis.localStorage.setItem(key, value);
  },
  get: (key: string): string => {
    // @ts-ignore
    return globalThis.localStorage.getItem(key);
  }
}

function JournalInput() {
  const { updateSource } = React.useContext(LedgerContext);
  const [value, setValue] = React.useState<string>(
    initLedgerSourceFromLocalStorage()
  );

  // TODO: Better "e" type
  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    // <div className="w-full h-full flex">
    //   <div className=" w-12 text-gray-400 p-1 text-right">
    //     {value.map((_, i) => (
    //       <div key={i}>{i + 1}</div>
    //     ))}
    //   </div>
    <div className="w-full h-full font-mono pl-2">
      <form className="w-full h-full" onSubmit={onSubmit}>
        <textarea
          className="resize-none outline-none w-full h-full"
          placeholder="Copy your ledger.journal file here"
          value={value}
          // TODO: Better "e" type
          onChange={(e: any) => {
            setValue(e.target.value);
            updateSource(e.target.value.split("\n"));
            ls.set(COOKIE_JOURNAL_SOURCE, e.target.value);
          }}
        />
      </form>
    </div>
  );
}

function Ledger() {
  const { data } = React.useContext(LedgerContext);
  console.log("data", data);
  // @ts-ignore
  const errorMessage: string | undefined = data.error;
  if (errorMessage)
    return (
      <div className="text-red-700 h-full w-full p-1">
        <div className="font-semibold">ERROR:</div>
        <div>{errorMessage}</div>
      </div>
    );
  // @ts-ignore
  const transactions: Transaction[] = data;

  return (
    <div className="h-full w-full p-1">
      {JSON.stringify(transactions.map(validateTransaction))}
      {JSON.stringify(balancesByAccountFromTransactions(transactions))}
      {transactions.map((tr: Transaction, ind: number) => (
        <div className="my-3" key={ind}>
          {JSON.stringify(tr, null, 2)}
        </div>
      ))}
    </div>
  );
}

function initLedgerSourceFromLocalStorage(): string {
  return ls.get(COOKIE_JOURNAL_SOURCE) || "";
}

export function HomePage() {
  const initLedgerSource = initLedgerSourceFromLocalStorage().split("\n");
  const [ledgerSourceContext, setLedgerSourceContext] = React.useState<
    ILedgerContext
  >({
    source: initLedgerSource,
    updateSource: (newSource: string[]): void => {
      setLedgerSourceContext({
        ...ledgerSourceContext,
        source: newSource,
        data: parseAndCatchErrors(newSource)
      });
    },
    data: parseAndCatchErrors(initLedgerSource)
  });

  return (
    <div className="h-screen overflow-y-hidden">
      <div className="w-full absolute">
        <div className="py-1 w-full text-center">
          <p className="uppercase text-2xl font-extrabold">Ledger</p>
        </div>
      </div>
      <LedgerContext.Provider value={ledgerSourceContext}>
        <div className="header-offset h-full flex">
          <div className="flex-1 h-full border-t-4 border-r-2 overflow-y-auto">
            <JournalInput />
          </div>
          <div className="flex-1 h-full border-t-4 border-l-2 bg-yellow-200 overflow-y-auto">
            <Ledger />
          </div>
        </div>
      </LedgerContext.Provider>
    </div>
  );
}
